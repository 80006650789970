
/* eslint-disable */

import CloudFun, { computed, defineComponent, ref } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import CheckBoxList from '@/cloudfun/components/CheckBoxList.vue'


export default defineComponent({
  components: {
    Grid,
    CheckBoxList,
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    var printColumns = [{ field: 'Name' }, { field: 'Description' }]

    const gridOptions: GridOptions = {
      id: 'import-member',
      title: '匯入名單',
      multiselect: true,
      canUpdate: false,
      canDelete: false,
      canRead: false,
      canCreate: false,
      toolbarConfig: {
        custom: false,
        refresh: false
      },
      printConfig: {
        sheetName: '匯入名單',
        columns: printColumns,
        modes: ['current', 'selected', 'all']
      },
      exportConfig: {
        filename: '匯入名單',
        type: 'csv',
        types: ['html', 'csv'],
        mode: 'all',
        modes: ['current', 'selected', 'all'],
        columns: printColumns
      },
      columns: [
        {
          field: 'email',
          title: '業務信箱',
           align: "center",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          editRender: {
            name: '$input',
            immediate: true,
            attrs: { type: 'text' }
          },
        },
        {
          field: 'status',
          title: '該帳號狀態',
           align: "center",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          editRender: {
            name: '$input',
            immediate: true,
            attrs: { type: 'text' }
          },
        },
        {
          field: 'process',
          title: '建議處理',
           align: "center",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          editRender: {
            name: '$input',
            immediate: true,
            attrs: { type: 'text' }
          },
        },
      ],
      promises: {
        // query: model
        //   ? (params) => model.dispatch('import-member/query', { pageSize: params.pageSize, page: params.page })
        //   : {"data":[{id: 1, name: 'import-member1'}], "intialPage":1,"page":1,"pageSize":10,"totalCount":1,"pageCount":1},
        query: () => {
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              resolve({
                "data":[
                  {
                    id: 1, 
                    email: "acd123.cisa.net",
                    status: "已是組織成員",
                    process: "無",
                  },
                  {
                    id: 2, 
                    email: "acd123.cisa.net",
                    status: "目前玩其他供應商成員",
                    process: "由系統寄發轉換通知信",
                  },
                  {
                    id: 3, 
                    email: "acd123.cisa.net",
                    status: "本次加入成功",
                    process: "勾選已加入組織",
                  },
                  {
                    id: 4, 
                    email: "acd123.cisa.net",
                    status: "查無該帳號",
                    process: "由系統寄發邀請碼",
                  }
                ],
                "totalCount":1,
              });
            }, 100);
          });
        },
        queryAll: model ? () => model.dispatch('import-member/query') : undefined,
        save: model
          ? (params) => model.dispatch('import-member/save', params)
          : undefined
      },
      modalConfig: { showFooter: true }
    }


    return {
      grid,
      gridOptions,
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    }
  }
})
